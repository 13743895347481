


















































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import EmergencyFundCardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/emergency-fund-card-view-model';

@Component({
  name: 'EmergencyFundCard',
  components: {
    LoadingDataCard,
    FlagshipLinkGoalsWizardPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardPocket.vue'),
    ModalPrepareDocumentsPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/ModalPrepareDocumentsPocket.vue'),
  },
})
export default class EmergencyFundCard extends Vue {
  emergency_fund_card_view_model = Vue.observable(new EmergencyFundCardViewModel());

  created() {
    this.emergency_fund_card_view_model.initialize();
  }
}
